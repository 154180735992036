.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-size: cover;
    background-image: linear-gradient(#74C1F5,#D1EDFF);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
  }

  .socialWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .socialicons{
    object-fit: contain;
    height: auto;
  }

  .body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    position: relative;
  }

  .title{
    color:white;
    font-family: var(--font-anton);
    margin: 0;
  }

  .caHeading,.ca{
    margin: 0;
    color: #E64D52;
    font-family: var(--font-anton);
    
  }

  .cawrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100px;
    border: 2px solid #E64D52;
  }

  .banner{
    position: absolute;
    width: 100%;
    left: 0;
    object-fit:cover;
    z-index: 1;
    top: 0%;
  }

  .subtitle{
    font-family: var(--font-bubbler);
    color: white;
    margin: 0px;
  }

  .penguin{
    position: absolute;
  }

  .copyWrapper{
    margin: 0;
    background-color: transparent;
    border: none;
  }

  .title_wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: -2;
    box-sizing: border-box;
  }

  .title_subwrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flag_wrapper{
    display: flex;
    width: 100%;
    position: relative;
  }

  .flag_image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .tramala_image{
    position: absolute;
    right: 0;
    bottom:95%;
    object-fit: contain;
    z-index: -1;
    animation: tramala_image 2s infinite;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 550px;
        min-height: 530px;
        /* background-image: url("../images/Section1/bg4.png"); */
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        top:92%;
        height:40px;
    }
    
    .socialWrapper{
        gap: 14px;
    }

    .socialicons{
        width: 24px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 40px;
    }

    .cawrapper{
        padding:10px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:20px;
    }

    .copyIcon{
        width:16px;
        height: auto;
    }

    .title{
        font-size:65px;
    }

    .title_wrapper{
        padding: 40px;
        gap: 10px;
    }

    .tramala_image{
        width: 200px;
        height:200px;
    }

    .star_icon{
        width: 35px;
        height: 35px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 880px;
        min-height: 800px;
        /* background-image: url("../images/Section1/bg4.png"); */
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        top:92%;
        height:75px;
    }
    
    .socialWrapper{
        gap: 30px;
    }

    .socialicons{
        width:40px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 30px;
    }

    .cawrapper{
        padding:14px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:26px;
    }

    .copyIcon{
        width:20px;
        height: auto;
    }

    .title{
        font-size:80px;
    }

    .title_wrapper{
        padding: 40px;
        gap: 15px;
    }

    .tramala_image{
        width: 300px;
        height:300px;
    }

    .star_icon{
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 980px;
        min-height: 900px;
        /* background-image: url("../images/Section1/bg3.png"); */
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        top:92%;
        height: 150px;
    }
    
    .socialWrapper{
        gap: 40px;
    }

    .socialicons{
        width:44px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 50px;
    }

    .cawrapper{
        padding:16px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:30px;
    }

    .copyIcon{
        width:26px;
        height: auto;
    }

    .title{
        font-size:110px;
    }

    .title_wrapper{
        padding:44px;
        padding-top: 100px;
        padding-bottom: 100px;
        gap:30px;
    }

    .tramala_image{
        width: 500px;
        height:500px;
    }

    .star_icon{
        width: 65px;
        height:65px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1080px;
        min-height: 1080px;
        /* background-image: url("../images/Section1/bg3.png"); */
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .banner{
        top:92%;
        height: 200px;
    }
    
    .socialWrapper{
        gap: 60px;
    }

    .socialicons{
        width:60px;
        border-radius: 100px;
    }

    .subtitle{
        font-size: 50px;
    }

    .cawrapper{
        padding: 20px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size:40px;
    }

    .copyIcon{
        width:34px;
        height: auto;
    }

    .title{
        font-size:120px;
    }

    .title_wrapper{
        padding: 50px;
        padding-top: 100px;
        padding-bottom: 100px;
        gap: 30px;
    }
    

    .tramala_image{
        width: 600px;
        height:600px;
    }

    .star_icon{
        width: 75px;
        height: 75px;
        object-fit: contain;
    }

  }

  @keyframes tramala_image {
    0%{
        bottom: 40%;
    }
    25%{
        bottom: 95%;
    }
    75%{
        bottom: 95%;
    }
    100%{
        bottom: 40%;
    }
  }

